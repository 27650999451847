export default class Movie {
    constructor() {
        this.player = null;
        this._init();
    }

    _init() {
        const modalbtn = document.querySelector(".js-moviemodal-btn");

        if (modalbtn !== null) {
            this._setup();
            this._eventBind();
        }
    }

    // 他のクラスから使用するメソッド
    resetEvent() {
        this._eventBind("reset");
    }

    _setup() {
        const tag = document.createElement("script");
        const firstBtn = document.querySelectorAll(".js-moviemodal-btn")[0];
        const videoID = firstBtn.getAttribute("data-videoID");

        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            this.player = new YT.Player("player", {
                height: "360",
                width: "640",
                videoId: videoID, // デフォルト height,widthは初期値を入れておくだけで、実際はcssでスタイル調整する
                events: {
                    onStateChange: this._onStateChange.bind(this),
                    onError: this._onError,
                },
            });
        };
    }

    _onError(e) {
        console.log(e);
    }

    _onStateChange(e) {
        //プレミア公開も考慮してバッファリング(3)のタイミングに変更。そしてsetTimeoutさせる。
        if (e.data === 3) {
            setTimeout(() => {
                document.querySelector(".p-movmodal__iframewrap").style.opacity = 1;
            }, 200);
        }
    }

    _eventBind(resetOption) {
        const openbuttons = [...document.querySelectorAll(".js-modal-open")];
        const closebuttons = [...document.querySelectorAll(".js-modal-close")];
        const videowrap = document.querySelector(".p-movmodal__iframewrap");

        const handleClose = () => {
            this.player.stopVideo();
            videowrap.style.opacity = 0; //クローズ時に非表示に。
        };

        const handleOpen = (e) => {
            const videoID = e.currentTarget.getAttribute("data-videoID");
            this._loadVideo(videoID);
        };

        openbuttons.forEach((el) => {
            resetOption && el.removeEventListener("click", handleOpen);
            el.addEventListener("click", handleOpen);
        });

        closebuttons.forEach((el) => {
            resetOption && el.removeEventListener("click", handleClose);
            el.addEventListener("click", handleClose);
        });
    }

    _loadVideo(id) {
        //デフォルトでautoplayされる。
        this.player.loadVideoById({ videoId: id });
    }
}